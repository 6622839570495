// Fonts
@tailwind base;
@tailwind components;
@tailwind utilities;

.content{background-color: #f1f5f9}

.align-center {
  align-items: center;
}

div[x-data="LivewireUIModal()"] {
  z-index: 9999999;
}

div[x-data="LivewireUIModal()"] .w-full.bg-white {
  max-width: 50vw;
  width: initial
}

.data-table {
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }

  tbody {
    -webkit-border-vertical-spacing: 10px;
  }

  .filter_row td {
    background: rgb(var(--color-slate-100)/var(--tw-bg-opacity)) !important;
  }
}

.ticket-reply-hover {
  div, p {
    max-width: 100%;
    display: block;
    white-space: initial !important;
  }

  .gmail_quote, .freshdesk_quote, #mail-editor-reference-message-container {
    display: none;
  }
}

div[x-ref="popover"] {
  justify-content: flex-start;
}

[id^=datatable-] {
  .shadow {
    box-shadow: none !important;
  }

 table {
    border-spacing: 0 5px;
    border-collapse: separate;

    > thead {
      background: transparent;
      th {
        background: transparent;
      }

      .uppercase {
        text-transform: unset;
        font-weight: initial;
        font-size: 13px;
        color: #333;
      }
    }

    > tbody {
      > tr {
        &:hover > td {
          background: #e2e8f0;
        }

        > td {
          background: #fff;

          &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }

          &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
    }

    > tfoot {
      tr {
        background: transparent!important;
        td {
          background: transparent!important;
        }
      }
    }
  }
}

div[x-data="wireui_dialog({ id: 'dialog' })"] h3{font-size: 26px!important;}


